html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

.Infobox, .InfoboxCustom {
    max-width: 12rem;
    min-height: 5rem;
    background: white !important;
    padding: 1rem !important;
    font-family: 'Urbanist' !important;
    border: 1px solid #1378A5 !important;
}

.infobox-title {
    font-size: 1.4rem !important;
    font-family: 'Urbanist' !important;
    margin-bottom: -10px !important;
}
.infobox-info {
    font-size: 1rem !important;
    font-family: 'Urbanist' !important;
    overflow: visible !important;
}