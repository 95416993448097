@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes myAnimRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes search-text {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes search-box {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.left-slider {
  animation: myAnim 3s ease 0.5s 1 normal forwards;
}
.right-slider {
  animation: myAnimRight 3s ease 0.5s 1 normal forwards;
}

.text-search {
  animation: search-text 2s ease 0.5s 1 normal forwards;
}

.search-box-animation {
  animation: search-box 2s ease 0.5s 1 normal forwards;
}

.btn-grad {
  width: 70%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: none;
  background-image: linear-gradient(
    to right,
    #4b6cb7 0%,
    #182848 51%,
    #4b6cb7 100%
  );

  padding: 10px 5px;
  text-align: center;
  text-transform: none;
  transition: 1s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.btn-grad:active {
  background-position: right center; /* change the direction of the change here */
  color: #5eff00;
}

.reveal-description-text {
  display: none;
  transition: 2s all ease-in-out;
}
.home-card {
  position: absolute;
  z-index: 10;
  background-color: #3067e7b6;
  transition: 1s all ease-in-out;
  height: 30%;
  width: 100%;
  bottom: 0;
}

.home-card:hover .reveal-description-text {
  animation: search-box 0.5s ease 0s 1 normal forwards;
  display: block;
}
.home-card:hover {
  height: 90%;
}

@media screen and (max-width: 600px) {
  .left-slider,
  .right-slider {
    color: #4b6cb7;
    display: none;
  }
  .text-search {
    font-size: 3em !important;
  }

  .btn-grad {
    width: 20em;
  }
}
